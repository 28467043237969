import React, { useEffect, useState } from 'react';

import {
  Box,
  Button,
  Checkbox,
  FormControl,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
} from '@mui/material';
import { useNavigate, useSearch } from '@tanstack/react-router';

import { useCLMComponentsConfig } from '../hooks/useCLMComponentsConfig';
import { useCLMSubcomponentHealth } from '../hooks/useCLMSubcomponentHealth';
import { useCLMSubcomponentInfo } from '../hooks/useCLMSubcomponentInfo';
import { CLMComponent, HealthStatus } from '../types';
import { isNotNullOrUndefined } from '../utils/utils';
import { BuildTimeFilterModal } from './BuildTimeFilterModal';

type Props = {
  setFilteredComponents: React.Dispatch<React.SetStateAction<CLMComponent[]>>;
};

export const Filter: React.FC<Props> = ({ setFilteredComponents }) => {
  const searchParams = useSearch({ from: '/info' });
  const navigate = useNavigate({ from: '/info' });
  const { data: clmComponentsConfig } = useCLMComponentsConfig();
  const { data: subcomponentHealth } = useCLMSubcomponentHealth({ components: clmComponentsConfig?.components });
  const { data: subcomponentInfo } = useCLMSubcomponentInfo({ components: clmComponentsConfig?.components });

  const [isModalOpen, setIsModalOpen] = useState(false);

  const [date, setDate] = useState<any>();
  const [range, setRange] = useState<string>('older');

  useEffect(() => {
    if (isNotNullOrUndefined(clmComponentsConfig)) {
      const components = Object.values(clmComponentsConfig.components).sort((a, b) => a.name.localeCompare(b.name));

      const teamsParam = searchParams.teams;
      const statusParam = searchParams.status;
      const buildParam = searchParams.build;

      let filtered = components;

      if (isNotNullOrUndefined(teamsParam)) {
        const teams = new Set(
          clmComponentsConfig.clmTeams
            .filter((team) => teamsParam.split(',').includes(team.shortName))
            .map((team) => team.key),
        );
        filtered = filtered.filter((component) => teams.has(component.team));
      }

      if (isNotNullOrUndefined(statusParam) && isNotNullOrUndefined(subcomponentHealth)) {
        const status = statusParam.split(',');

        const temp: Record<string, HealthStatus> = {};

        components.forEach((component) => {
          Object.values(component.subComponents).forEach((subcomponent) => {
            temp[subcomponent.name] = subcomponentHealth[component.name][subcomponent.name].status;
          });
        });

        filtered = filtered.filter((component) => {
          return Object.values(component.subComponents).some((subcomponent) => {
            return status.includes(temp[subcomponent.name]);
          });
        });
      }
      if (isNotNullOrUndefined(buildParam) && isNotNullOrUndefined(subcomponentInfo)) {
        const buildTime: any = {};

        components.forEach((component) => {
          Object.values(component.subComponents).forEach((subcomponent) => {
            buildTime[subcomponent.name] = subcomponentInfo[component.name][subcomponent.name]?.build.time;
          });
        });

        const date = new Date(buildParam).toISOString();

        filtered = filtered.filter((component) => {
          return Object.values(component.subComponents).some((subcomponent) => {
            if (range === 'older') {
              return buildTime[subcomponent.name] < date;
            } else if (range === 'newer') {
              return buildTime[subcomponent.name] > date;
            }
          });
        });
      }
      setFilteredComponents(filtered);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams, subcomponentInfo, subcomponentHealth]);

  return (
    <Box
      sx={{
        display: 'flex',
        gap: '1rem',
      }}
    >
      <FormControl>
        <InputLabel id="teams-filter-label" size="small">
          Filter Teams
        </InputLabel>
        <Select
          sx={{ minWidth: '200px' }}
          labelId="teams-filter-label"
          multiple
          size="small"
          value={searchParams.teams?.split(',') || []}
          input={<OutlinedInput label="Filter Teams" />}
          renderValue={(selected) => selected.join(', ')}
          MenuProps={{
            PaperProps: {
              style: {
                maxHeight: 48 * 4.5 + 8,
                width: 250,
              },
            },
          }}
          onChange={async (e) => {
            const value = e.target.value as string[];
            await navigate({
              search: (prev) => ({ ...prev, teams: value.length > 0 ? value.join(',') : undefined }),
            });
          }}
          data-testid="team-filter-select"
        >
          {clmComponentsConfig &&
            clmComponentsConfig.clmTeams
              .sort((a, b) => a.name.localeCompare(b.name))
              .map((team) => (
                <MenuItem key={team.shortName} value={team.shortName}>
                  <Checkbox checked={searchParams.teams?.split(',').includes(team.shortName) || false} />
                  <ListItemText primary={team.name} />
                </MenuItem>
              ))}
        </Select>
      </FormControl>
      <FormControl>
        <InputLabel id="status-filter-label" size="small">
          Filter Status
        </InputLabel>
        <Select
          sx={{ minWidth: '200px' }}
          labelId="status-filter-label"
          multiple
          size="small"
          value={searchParams.status?.split(',') || []}
          input={<OutlinedInput label="Filter Status" />}
          renderValue={(selected) => selected.join(', ')}
          MenuProps={{
            PaperProps: {
              style: {
                maxHeight: 48 * 4.5 + 8,
                width: 250,
              },
            },
          }}
          onChange={async (e) => {
            const value = e.target.value as string[];
            await navigate({
              search: (prev) => ({ ...prev, status: value.length > 0 ? value.join(',') : undefined }),
            });
          }}
          data-testid="status-filter-select"
        >
          {['UP', 'DOWN', 'UNKNOWN', 'OUT_OF_SERVICE']
            .sort((a, b) => a.localeCompare(b))
            .map((status) => (
              <MenuItem key={status} value={status} data-testid={status}>
                <Checkbox checked={searchParams.status?.split(',').includes(status) || false} />
                <ListItemText primary={status} />
              </MenuItem>
            ))}
        </Select>
      </FormControl>
      <Box>
        <Button onClick={() => setIsModalOpen(true)} data-testid="open-build-time-modal">
          Change Build Time Filter
        </Button>
        {isModalOpen ? (
          <BuildTimeFilterModal
            onClose={() => setIsModalOpen(false)}
            date={date}
            range={range}
            setRange={setRange}
            setDate={setDate}
          />
        ) : null}
      </Box>
    </Box>
  );
};
