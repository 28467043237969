import React, { useEffect, useState } from 'react';

import { JBButton, JBIcon } from '@juliusbaer-gcrm/gcrm-frontend-components';
import { Box, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from '@mui/material';

import { useCLMComponentsConfig } from '../hooks/useCLMComponentsConfig';
import { useCLMSubcomponentHealth } from '../hooks/useCLMSubcomponentHealth';
import { useCLMSubcomponentInfo } from '../hooks/useCLMSubcomponentInfo';

const refetchTime = 300;

export const RefetchCountdown: React.FC = () => {
  const [refetchInterval, setRefetchInterval] = useState<number>(refetchTime);
  const [isRefetchIntervalDialogOpen, setIsRefetchIntervalDialogOpen] = useState<boolean>(false);
  const [refetchCounter, setRefetchCounter] = useState<number>(refetchTime);
  const [lastRefetchTime, setLastRefetchTime] = useState<number>(Date.now());

  const { data } = useCLMComponentsConfig();
  const { refetch: refetchHealth } = useCLMSubcomponentHealth({ components: data?.components });
  const { refetch: refetchInfo } = useCLMSubcomponentInfo({ components: data?.components });

  useEffect(() => {
    document.title = 'CLM Components Status';

    const interval = setInterval(() => {
      setRefetchCounter((prev) => {
        if (prev === 0) {
          refetchHealth();
          refetchInfo();

          setLastRefetchTime(Date.now());
          return refetchInterval;
        }
        return prev - 1;
      });
    }, 1000);

    return () => clearInterval(interval);
  }, [refetchInterval, refetchHealth, refetchInfo]);

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        gap: '1rem',
      }}
    >
      <JBButton
        size="small"
        variant="contained"
        onClick={() => setIsRefetchIntervalDialogOpen(true)}
        data-testid="refetch-interval-modal-button"
        startIcon={<JBIcon iconName="edit" size="sm" />}
      >
        Change Interval
      </JBButton>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: '5px',
        }}
      >
        <Box data-testid="refetch-interval-info-text">
          Refetching in: {Math.floor(refetchCounter / 60)}m {Math.floor(refetchCounter % 60)}s
        </Box>
        <Box>
          Last time refetched:{' '}
          {new Date(lastRefetchTime).toLocaleTimeString('de-DE', {
            hour: '2-digit',
            minute: '2-digit',
          })}
        </Box>
      </Box>
      <Dialog open={isRefetchIntervalDialogOpen} onClose={() => setIsRefetchIntervalDialogOpen(false)}>
        <DialogTitle>Change Refetch Interval</DialogTitle>
        <DialogContent data-testid="refetch-interval-modal">
          <Box>
            <TextField
              type="number"
              onChange={(e) => {
                const value = e.target.value;
                setRefetchInterval(Number(value));
              }}
              value={refetchInterval}
              placeholder="Enter new refetch interval"
              variant="outlined"
              sx={{
                paddingTop: '1px',
                width: '100%',
              }}
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <JBButton onClick={() => setIsRefetchIntervalDialogOpen(false)}>Cancel</JBButton>
          <JBButton
            variant="contained"
            onClick={() => {
              setRefetchInterval(refetchInterval);
              setIsRefetchIntervalDialogOpen(false);
            }}
            data-testid="refetch-interval-set-button"
          >
            Set
          </JBButton>
        </DialogActions>
      </Dialog>
    </Box>
  );
};
