import { useQuery } from '@tanstack/react-query';
/* eslint-disable import/no-named-as-default-member */
import axios, { AxiosError } from 'axios';

import { axiosInstance } from '../shared/axios/axiosConfig';
import { CLMComponent, CLMSubcomponentHealth, HealthStatus } from '../types';
import { assertNotNullOrUndefined, isNotNullOrUndefined } from '../utils/utils';

type Props = {
  components?: Record<string, CLMComponent>;
};

export const useCLMSubcomponentHealth = ({ components }: Props) => {
  const query = useQuery<Record<string, Record<string, CLMSubcomponentHealth>> | undefined, AxiosError>({
    queryKey: ['clm-subcomponent-health'],
    queryFn: async () => {
      assertNotNullOrUndefined(components);
      const componentsAsArray = Object.values(components);

      const subcomponents = componentsAsArray.flatMap((component) => Object.values(component.subComponents));

      const results = await Promise.all(subcomponents.map((subcomponent) => fetchData(subcomponent.name)));

      const temp: Record<string, CLMSubcomponentHealth> = {};
      results.forEach((result) => (temp[result.name] = result.payload));

      const subcomponentHealth: Record<string, Record<string, CLMSubcomponentHealth>> = {};
      componentsAsArray.forEach((component) => {
        subcomponentHealth[component.name] = {};

        Object.values(component.subComponents).forEach((subcomponent) => {
          subcomponentHealth[component.name][subcomponent.name] = temp[subcomponent.name];
        });
      });

      return subcomponentHealth;
    },
    retry: 1,
    enabled: isNotNullOrUndefined(components),
  });

  const fetchData = async (subcomponentName: string) => {
    try {
      const { data } = await axiosInstance.get(`/backend/${subcomponentName}/actuator/health`);

      return {
        name: subcomponentName,
        payload: data,
      };
    } catch (error) {
      if (axios.isAxiosError(error) && error.response?.status !== 404) {
        const data = error.response?.data;
        const contentType = error.response?.headers['content-type'];

        const validHealthStatuses: HealthStatus[] = ['UP', 'DOWN', 'OUT_OF_SERVICE', 'UNKNOWN'];

        if (contentType === 'application/json' && validHealthStatuses.includes(data.status)) {
          return {
            name: subcomponentName,
            payload: data,
          };
        } else {
          return {
            name: subcomponentName,
            payload: {
              status: 'UNKNOWN',
              groups: [],
            },
          };
        }
      } else {
        return {
          name: subcomponentName,
          payload: {
            status: 'UNKNOWN',
            groups: [],
          },
        };
      }
    }
  };

  return query;
};
