import React, { useState } from 'react';

import { Box, Divider } from '@mui/material';

import { CLMComponentList } from '../components/CLMComponentList';
import { Filter } from '../components/Filter';
import { RefetchCountdown } from '../components/RefetchCountdown';
import { useCLMComponentsConfig } from '../hooks/useCLMComponentsConfig';
import { CLMComponent } from '../types';
import { isNotNullOrUndefined } from '../utils/utils';

function InfoOverviewPage() {
  const [filteredCLMComponents, setFilteredCLMComponents] = useState<CLMComponent[]>([]);
  const { data } = useCLMComponentsConfig();
  return (
    <Box>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'end',
          padding: '1rem',
        }}
        data-testid="info-overview-header"
      >
        {isNotNullOrUndefined(data) ? (
          <>
            <RefetchCountdown />
            <Box
              sx={{
                marginX: '1rem',
                backgroundColor: 'lightgray',
                width: '1px',
              }}
            />
            <Filter setFilteredComponents={setFilteredCLMComponents} />
          </>
        ) : null}
      </Box>
      <Divider />
      <CLMComponentList components={filteredCLMComponents} />
    </Box>
  );
}

export default InfoOverviewPage;
