import React from 'react';

import { JBButton, JBColors, JBIcon } from '@juliusbaer-gcrm/gcrm-frontend-components';
import { Box } from '@mui/material';
import { useMutation } from '@tanstack/react-query';

import { useRuntimeConfig } from '../hooks/useRuntimeConfig';
import LinkService from '../services/LinkService';
import { isNullOrUndefined } from '../utils/utils';
import { LoginTypeDto } from './types/LoginType';

type Props = {
  user: LoginTypeDto | null;
};

export const ConfigurationDownloadButton: React.FC<Props> = ({ user }) => {
  const { data: runtimeConfig } = useRuntimeConfig();

  const { mutateAsync, error } = useMutation({
    mutationFn: async (user: LoginTypeDto) => {
      return await LinkService.renderLinks(user.userId, user.password);
    },
  });

  const handleDownload = async () => {
    if (isNullOrUndefined(user)) return;
    if (isNullOrUndefined(runtimeConfig)) return;

    const links = await mutateAsync(user);

    const command = `"C:\\Program Files (x86)\\Microsoft\\Edge\\Application\\msedge.exe" -inprivate --user-data-dir="C:\\test-session-${runtimeConfig.env}-${user.userId}"`;

    const downloadUrl = URL.createObjectURL(
      new Blob(
        [
          command,
          ' ',
          links
            .sort((a, b) => a.localeCompare(b))
            .join(' ')
            .replaceAll('%', '%%'),
        ],
        { type: 'text/plain' },
      ),
    );

    const downloadElement = document.createElement('a');
    downloadElement.style.display = 'none';
    downloadElement.download = `CLM_Login_${runtimeConfig.env}_${user.userId}.cmd`;
    downloadElement.href = downloadUrl;
    document.body.append(downloadElement);
    downloadElement.click();
    downloadElement.remove();
  };

  return (
    <Box>
      <JBButton
        startIcon={<JBIcon iconName="documentDownload" />}
        disabled={isNullOrUndefined(user)}
        onClick={handleDownload}
        data-testid="download-windows-login-shortcut"
      >
        Download Windows Shortcut
      </JBButton>
      <Box
        sx={{
          color: JBColors.support.red['100'],
          textAlign: 'center',
        }}
      >
        {error?.message}
      </Box>
    </Box>
  );
};
