import React from 'react';

import { Grid2, Typography } from '@mui/material';

import { Footnotes } from '../components/Disclaimer/Footnotes';
import Login from '../components/Login/Login';

function ImpersonatePage() {
  return (
    <Grid2 container direction="column" alignItems="center" justifyContent="center">
      <Typography variant="h4" data-testid="impersonate-add-title">
        Impersonate Test User
      </Typography>
      <Login />
      <Footnotes />
    </Grid2>
  );
}

export default ImpersonatePage;
