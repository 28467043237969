import React, { SetStateAction } from 'react';

import { Box, Dialog, FormControl, FormControlLabel, Radio, RadioGroup } from '@mui/material';
import { StaticDatePicker } from '@mui/x-date-pickers';
import { JBButton } from '@juliusbaer-gcrm/gcrm-frontend-components';
import { useNavigate } from '@tanstack/react-router';
import dayjs from 'dayjs';

import { isNotNullOrUndefined } from '../utils/utils';

type Props = {
  date: any;
  range: string;
  setRange: SetStateAction<any>;
  setDate: SetStateAction<any>;
  onClose: () => void;
};

export const BuildTimeFilterModal: React.FC<Props> = ({ date, range, setRange, setDate, onClose }) => {
  const navigate = useNavigate({ from: '/info' });

  const today = new Date();

  const handleChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    setRange(event.target.value);
    await navigate({
      search: (prev) => ({
        ...prev,
        olderOrNewer: isNotNullOrUndefined(event.target.value) ? event.target.value : undefined,
      }),
    });
  };

  return (
    <Dialog open={true} data-testid="filter-modal">
      <Box
        sx={{
          padding: '1rem',
        }}
      >
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <FormControl>
            <RadioGroup row>
              <FormControlLabel
                control={<Radio checked={range === 'older'} value="older" onChange={handleChange} />}
                label="Older"
              />
              <FormControlLabel
                control={<Radio checked={range === 'newer'} value="newer" onChange={handleChange} />}
                label="Newer"
              />
            </RadioGroup>
          </FormControl>
          <JBButton onClick={() => onClose()}>Close</JBButton>
        </Box>
        <Box>
          <StaticDatePicker
            onChange={async (value: any) => {
              setDate(new Date(value).toISOString());
              await navigate({
                search: (prev) => ({
                  ...prev,
                  build: isNotNullOrUndefined(value) ? new Date(value).toISOString() : undefined,
                  olderOrNewer: range,
                }),
              });
            }}
            value={dayjs(date)}
            slotProps={{ actionBar: { actions: [] } }}
          />
          <JBButton
            variant="contained"
            onClick={async () => {
              const newDate = new Date(new Date(today).setHours(today.getHours() - 4)).toISOString();
              setDate(newDate);
              setRange('older');
              await navigate({
                search: (prev) => ({ ...prev, build: isNotNullOrUndefined(newDate) ? newDate : undefined }),
              });
            }}
          >
            4 hours
          </JBButton>
          <JBButton
            variant="contained"
            onClick={async () => {
              const newDate = new Date(new Date(today).setDate(today.getDate() - 1)).toISOString();
              setDate(newDate);
              setRange('older');
              await navigate({
                search: (prev) => ({ ...prev, build: isNotNullOrUndefined(newDate) ? newDate : undefined }),
              });
            }}
          >
            1 day
          </JBButton>
          <JBButton
            variant="contained"
            onClick={async () => {
              const newDate = new Date(new Date(today).setMonth(today.getMonth() - 1)).toISOString();
              setDate(newDate);
              setRange('older');
              await navigate({
                search: (prev) => ({ ...prev, build: isNotNullOrUndefined(newDate) ? newDate : undefined }),
              });
            }}
          >
            1 month
          </JBButton>
          <JBButton
            variant="contained"
            onClick={async () => {
              const newDate = new Date(new Date(today).setMonth(today.getMonth() - 3)).toISOString();

              setDate(newDate);
              setRange('older');
              await navigate({
                search: (prev) => ({ ...prev, build: isNotNullOrUndefined(newDate) ? newDate : undefined }),
              });
            }}
          >
            3 months
          </JBButton>
          <JBButton
            onClick={async () => {
              await navigate({
                search: (prev) => ({ ...prev, build: undefined, olderOrNewer: undefined }),
              });
              setDate('');
              setRange('older');
            }}
          >
            Clear
          </JBButton>
        </Box>
      </Box>
    </Dialog>
  );
};
