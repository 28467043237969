import { useQuery } from '@tanstack/react-query';
import axios from 'axios';

/* eslint-disable import/no-named-as-default-member */
import { axiosInstance } from '../shared/axios/axiosConfig';
import { CLMComponent, CLMSubcomponentInfo } from '../types';
import { assertNotNullOrUndefined, isNotNullOrUndefined } from '../utils/utils';

type Props = {
  components?: Record<string, CLMComponent>;
};

export const useCLMSubcomponentInfo = ({ components }: Props) => {
  const query = useQuery({
    queryKey: ['clm-subcomponent-info'],
    queryFn: async () => {
      assertNotNullOrUndefined(components);
      const componentsAsArray = Object.values(components);

      const subcomponents = componentsAsArray.flatMap((component) => Object.values(component.subComponents));

      const results = await Promise.all(subcomponents.map((subcomponent) => fetchData(subcomponent.name)));

      const temp: Record<string, CLMSubcomponentInfo | undefined> = {};
      results.forEach((result) => (temp[result.name] = result.payload));

      const subcomponentInfo: Record<string, Record<string, CLMSubcomponentInfo | undefined>> = {};
      componentsAsArray.forEach((component) => {
        subcomponentInfo[component.name] = {};

        Object.values(component.subComponents).forEach((subcomponent) => {
          subcomponentInfo[component.name][subcomponent.name] = temp[subcomponent.name];
        });
      });

      return subcomponentInfo;
    },
    retry: 1,
    enabled: isNotNullOrUndefined(components),
  });

  const fetchData = async (subComponentName: string) => {
    try {
      const { data } = await axiosInstance.get(`/backend/${subComponentName}/actuator/info`);

      return {
        name: subComponentName,
        payload: data,
      };
    } catch (error) {
      if (axios.isAxiosError(error)) {
        return {
          name: subComponentName,
          payload: undefined,
        };
      } else {
        throw error;
      }
    }
  };

  return query;
};
