import React, { useState } from 'react';

import { JBButton, JBIcon } from '@juliusbaer-gcrm/gcrm-frontend-components';
import { Box, IconButton, Tooltip } from '@mui/material';

import { useCLMComponentsConfig } from '../hooks/useCLMComponentsConfig';
import { useCLMSubcomponentHealth } from '../hooks/useCLMSubcomponentHealth';
import { CLMComponent, CLMSubcomponent, CLMSubcomponentHealth } from '../types';
import { isNotNullOrUndefined, isNullOrUndefined } from '../utils/utils';
import { JsonViewModal } from './JsonViewModal';

type Props = {
  component: CLMComponent;
  subcomponent: CLMSubcomponent;
  isLoading: boolean;
};

export const ComponentHealth: React.FC<Props> = ({ component, subcomponent, isLoading }) => {
  const [isModalOpen, setModalOpen] = useState(false);

  const { data } = useCLMComponentsConfig();
  const { data: subcomponentHealth } = useCLMSubcomponentHealth({ components: data?.components });

  const renderHealthDetails = () => {
    if (isNullOrUndefined(subcomponentHealth)) return null;

    const health = subcomponentHealth[component.name][subcomponent.name];
    const components: CLMSubcomponentHealth['components'] =
      subcomponentHealth[component.name][subcomponent.name].components;

    const details: React.ReactNode[] = [];

    details.push(
      <li
        key={'main'}
        style={{
          paddingBottom: 5,
          fontSize: '1rem',
          maxWidth: '25%',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <Box>Health</Box>
          <Tooltip title={health.status} placement="right">
            <IconButton>
              <JBIcon
                dataTestId="status-icon-main"
                iconName={
                  isNullOrUndefined(health.status)
                    ? 'warningOutline'
                    : health.status === 'UP'
                      ? 'successSolid'
                      : 'failureSolid'
                }
                fill={isNullOrUndefined(health.status) ? 'blue' : health.status === 'UP' ? 'green' : 'red'}
              />
            </IconButton>
          </Tooltip>
        </Box>
      </li>,
    );

    if (isNotNullOrUndefined(components)) {
      Object.keys(components).forEach((name) => {
        details.push(
          <li
            key={name}
            style={{
              paddingBottom: 5,
              fontSize: '1rem',
              maxWidth: '25%',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
              }}
            >
              <Box>{name}</Box>
              <Tooltip title={components[name].status} placement="right">
                <IconButton>
                  <JBIcon
                    dataTestId={`status-icon-${name}`}
                    iconName={components[name].status === 'UP' ? 'successSolid' : 'failureSolid'}
                    fill={components[name].status === 'UP' ? 'green' : 'red'}
                  />
                </IconButton>
              </Tooltip>
            </Box>
          </li>,
        );
      });
    } else {
      ['readinessState', 'livenessState'].forEach((name) => {
        details.push(
          <li
            key={name}
            style={{
              paddingBottom: 5,
              fontSize: '1rem',
              maxWidth: '25%',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
              }}
            >
              <Box>{name}</Box>
              <Tooltip title={'UNKNOWN'} placement="right">
                <IconButton>
                  <JBIcon iconName="warningOutline" fill="blue" dataTestId={`status-icon-${name}`} />
                </IconButton>
              </Tooltip>
            </Box>
          </li>,
        );
      });
    }

    return details;
  };

  return (
    <>
      <div data-testid="health-details">
        {isLoading || isNullOrUndefined(subcomponentHealth) ? (
          <div>Subcomponent: {subcomponent.name} is Loading</div>
        ) : (
          <div>
            <div>
              <Box sx={{ padding: 0, fontSize: '1rem' }}>
                <strong>Health Details</strong>
                <JBButton onClick={() => setModalOpen(true)}>
                  <JBIcon iconName="viewDocument" data-testId="json-viewer-health" />
                </JBButton>
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: 1,
                  fontSize: '1rem',
                }}
              >
                <ul
                  style={{
                    padding: 0,
                    marginTop: 5,
                    marginLeft: 20,
                    display: 'flex',
                    flexDirection: 'column',
                  }}
                >
                  {renderHealthDetails()}
                </ul>
              </Box>
            </div>
            <JsonViewModal
              data={subcomponentHealth[component.name][subcomponent.name]}
              open={isModalOpen}
              onClose={() => setModalOpen(false)}
            />
          </div>
        )}
      </div>
    </>
  );
};
