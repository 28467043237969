import { useQuery } from '@tanstack/react-query';

import { axiosInstance } from '../shared/axios/axiosConfig';
import { RuntimeConfig } from '../types';

export const getRuntimeConfig = () => ({
  queryKey: ['runtime-config'],
  queryFn: async () => {
    const { data } = await axiosInstance.get('/runtime-config');
    return data;
  },
});

export const useRuntimeConfig = () => {
  return useQuery<RuntimeConfig>(getRuntimeConfig());
};
