import React, { useState } from 'react';

import { JBButton, JBIcon } from '@juliusbaer-gcrm/gcrm-frontend-components';
import { Box, Grid2 } from '@mui/material';

import { useCLMComponentsConfig } from '../hooks/useCLMComponentsConfig';
import { useCLMSubcomponentInfo } from '../hooks/useCLMSubcomponentInfo';
import { CLMComponent, CLMSubcomponent } from '../types';
import { isNotNullOrUndefined, renderBuildTime } from '../utils/utils';
import { JsonViewModal } from './JsonViewModal';

type Props = {
  component: CLMComponent;
  subcomponent: CLMSubcomponent;
};

const ComponentInfo: React.FC<Props> = ({ component, subcomponent }) => {
  const [isModalOpen, setModalOpen] = useState(false);
  const { data } = useCLMComponentsConfig();
  const { data: subcomponentInfo } = useCLMSubcomponentInfo({ components: data?.components });

  let gitUrl = '';

  if (isNotNullOrUndefined(subcomponentInfo)) {
    const info = subcomponentInfo[component.name][subcomponent.name];
    if (
      isNotNullOrUndefined(info) &&
      isNotNullOrUndefined(info.git) &&
      isNotNullOrUndefined(info.git.remote) &&
      isNotNullOrUndefined(info.git.remote.origin)
    ) {
      gitUrl = info.git.remote.origin.url.replace('https://project-access-token@', 'https://') ?? '';
    }
  }

  return (
    <>
      {isNotNullOrUndefined(subcomponentInfo) &&
      isNotNullOrUndefined(subcomponentInfo[component.name][subcomponent.name]) ? (
        <div data-testid="build-information">
          <Grid2>
            <Box
              sx={{
                fontSize: '1rem',
              }}
            >
              <strong>Build Information</strong>
              <JBButton onClick={() => setModalOpen(true)}>
                <JBIcon iconName="viewDocument" data-testId="json-viewer-info" />
              </JBButton>
            </Box>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: 1,
              }}
            >
              {isNotNullOrUndefined(subcomponentInfo[component.name][subcomponent.name]?.build) ? (
                <Box sx={{ display: 'flex', gap: '.5rem' }}>
                  <strong>Version: </strong>
                  <Box data-testid="component-info-version">
                    {subcomponentInfo[component.name][subcomponent.name]?.build.version}
                  </Box>
                </Box>
              ) : null}

              {isNotNullOrUndefined(subcomponentInfo[component.name][subcomponent.name]?.build) ? (
                <Box sx={{ display: 'flex', gap: '.5rem' }}>
                  <strong>Build time: </strong>
                  <Box data-testid="component-info-build-time">
                    {renderBuildTime({
                      component,
                      subcomponent,
                      subcomponentInfo,
                    })}
                  </Box>
                </Box>
              ) : null}

              {isNotNullOrUndefined(subcomponentInfo[component.name][subcomponent.name]?.git) ? (
                <Box sx={{ display: 'flex', gap: '.5rem' }}>
                  <strong>Git URL: </strong>
                  <Box>
                    <a href={gitUrl} data-testid="component-info-git-url">
                      {gitUrl}
                    </a>
                  </Box>
                </Box>
              ) : null}

              {isNotNullOrUndefined(subcomponentInfo[component.name][subcomponent.name]?.git) ? (
                <Box sx={{ display: 'flex', gap: '.5rem' }}>
                  <strong>Last commit message: </strong>
                  <Box data-testid="component-info-last-commit-message">
                    {subcomponentInfo[component.name][subcomponent.name]?.git?.commit.message?.full}
                  </Box>
                </Box>
              ) : null}

              {isNotNullOrUndefined(subcomponentInfo[component.name][subcomponent.name]?.kubernetes) ? (
                <Box sx={{ display: 'flex', gap: '.5rem' }}>
                  <strong>Kubernetes Info </strong>
                  <Box data-testid="component-info-kubernetes-info">
                    {subcomponentInfo[component.name][subcomponent.name]?.kubernetes?.namespace}
                  </Box>
                </Box>
              ) : null}

              {isNotNullOrUndefined(subcomponentInfo) ? (
                <JsonViewModal
                  data={subcomponentInfo[component.name][subcomponent.name]}
                  open={isModalOpen}
                  onClose={() => setModalOpen(false)}
                />
              ) : null}
            </Box>
          </Grid2>
        </div>
      ) : null}
    </>
  );
};

export default ComponentInfo;
