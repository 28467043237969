import React from 'react';

import { JBColors } from '@juliusbaer-gcrm/gcrm-frontend-components';
import { Box, Skeleton } from '@mui/material';
import Grid from '@mui/material/Grid2';
import { useIsFetching } from '@tanstack/react-query';

import { useCLMComponentsConfig } from '../hooks/useCLMComponentsConfig';
import { useValidation } from '../hooks/useValidation';
import { CLMComponent } from '../types';
import { isNotNullOrUndefined } from '../utils/utils';
import { CLMComponentListItem } from './CLMComponentListItem';

type Props = {
  components: CLMComponent[];
};

export const CLMComponentList: React.FC<Props> = ({ components }) => {
  const { data } = useCLMComponentsConfig();
  const validation = useValidation({ components: data?.components });
  const isFetching = useIsFetching({
    predicate: (query) => {
      return query.queryKey.some(
        (key) =>
          typeof key === 'string' && (key.includes('clm-component-info') || key.includes('clm-component-health')),
      );
    },
    exact: false,
  });

  const renderValidation = () => {
    const errors: string[][] = [];
    const warnings: string[][] = [];
    if (isNotNullOrUndefined(validation)) {
      for (const component of Object.values(validation)) {
        for (const subcomponent of Object.values(component)) {
          errors.push(subcomponent.errors);
          warnings.push(subcomponent.warnings);
        }
      }

      const numberOfSubcomponents = warnings.length;
      const numberOfErrors = errors.filter((error) => error.length > 0).length;
      const numberOfWarnings = warnings.filter((warning) => warning.length > 0).length;

      return (
        <Box
          sx={{
            display: 'flex',
            gap: '1rem',
          }}
        >
          <Box sx={{ display: 'flex', gap: '0.5rem' }}>
            <Box sx={{ fontWeight: 'bold' }}>UP:</Box>
            <Box>
              {numberOfSubcomponents - numberOfErrors}/{numberOfSubcomponents}
            </Box>
          </Box>
          <Box sx={{ display: 'flex', gap: '0.5rem' }}>
            <Box sx={{ fontWeight: 'bold' }}>DOWN:</Box>
            <Box>
              {numberOfErrors}/{numberOfSubcomponents}
            </Box>
          </Box>
          <Box sx={{ display: 'flex', gap: '0.5rem' }}>
            <Box sx={{ fontWeight: 'bold' }}>Warnings:</Box>
            <Box>
              {numberOfWarnings}/{numberOfSubcomponents}
            </Box>
          </Box>
        </Box>
      );
    }

    return null;
  };

  return (
    <Box>
      <Box
        sx={{
          justifyContent: 'space-between',
          alignItems: 'center',
          display: 'flex',
          padding: '2rem 1rem',
        }}
      >
        <Box
          sx={{
            fontSize: '2rem',
          }}
        >
          Components
        </Box>
        <Box>
          <Box>{renderValidation()}</Box>
        </Box>
      </Box>
      <Grid container columns={4}>
        {isFetching
          ? [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map((key) => (
              <Grid key={key} size={1}>
                <Skeleton
                  variant="rectangular"
                  height={100}
                  sx={{
                    backgroundColor: JBColors.core.stone['40'],
                    padding: '1rem',
                    margin: '10px',
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '10px',
                  }}
                />
              </Grid>
            ))
          : components.map((component) => <CLMComponentListItem component={component} key={component.name} />)}
      </Grid>
    </Box>
  );
};
