import React from 'react';

import { Box, Tooltip } from '@mui/material';
import { Link, useSearch } from '@tanstack/react-router';

import { useCLMComponentsConfig } from '../hooks/useCLMComponentsConfig';
import { useValidation } from '../hooks/useValidation';
import { CLMComponent, CLMSubcomponent, CLMSubcomponentHealth, CLMSubcomponentInfo } from '../types';
import { isNotNullOrUndefined, isNullOrUndefined, renderBuildTime } from '../utils/utils';

type Props = {
  index: number;
  component: CLMComponent;
  subcomponent: CLMSubcomponent;
  subcomponentInfo: Record<string, Record<string, CLMSubcomponentInfo | undefined>> | undefined;
  subcomponentHealth: Record<string, Record<string, CLMSubcomponentHealth>> | undefined;
};

export const CLMSubcomponentListItem: React.FC<Props> = ({
  index,
  component,
  subcomponent,
  subcomponentHealth,
  subcomponentInfo,
}) => {
  const searchParams = useSearch({ from: '/info' });
  const { data } = useCLMComponentsConfig();
  const validation = useValidation({ components: data?.components });

  return (
    <Link
      to={`/info/component/${subcomponent.name}`}
      style={{
        textDecoration: 'none',
        display:
          isNotNullOrUndefined(searchParams.status) &&
          isNotNullOrUndefined(subcomponentHealth) &&
          !searchParams.status.split(',').includes(subcomponentHealth[component.name][subcomponent.name].status)
            ? 'none'
            : 'block',
      }}
    >
      {isNotNullOrUndefined(validation) ? (
        <Tooltip
          arrow
          title={validation[component.name][subcomponent.name].tooltip()}
          disableHoverListener={isNullOrUndefined(validation[component.name][subcomponent.name].tooltip())}
        >
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              gap: '10px',
              border: '1px solid black',
              borderBottom: index === Object.values(component.subComponents).length - 1 ? '1px solid black' : 'none',
              padding: '2px 5px',
              color: 'black',
              backgroundColor: validation[component.name][subcomponent.name].color(),
            }}
          >
            <Box data-testid={subcomponent.name}>{subcomponent.name}</Box>

            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'end',
                gap: '2px',
              }}
            >
              <Box
                sx={{
                  fontWeight: 'bold',
                }}
              >
                {isNotNullOrUndefined(subcomponentInfo) &&
                  subcomponentInfo[component.name][subcomponent.name]?.build.version}
              </Box>
              <Box sx={{ whiteSpace: 'nowrap' }}>
                {renderBuildTime({
                  component,
                  subcomponent,
                  subcomponentInfo,
                })}
              </Box>
            </Box>
          </Box>
        </Tooltip>
      ) : null}
    </Link>
  );
};
