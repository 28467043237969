import React from 'react';

import { JBColors } from '@juliusbaer-gcrm/gcrm-frontend-components';
import { Box, CircularProgress } from '@mui/material';

export const Loader: React.FC = () => {
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        minWidth: '100dvw',
        minHeight: '100dvh',
        color: JBColors.core.reflexBlue['100'],
      }}
    >
      <CircularProgress color="inherit" />
    </Box>
  );
};
