import React from 'react';

import { JBColors } from '@juliusbaer-gcrm/gcrm-frontend-components';
import { Box } from '@mui/material';

import { CLMComponent, CLMComponentValidation } from '../types';
import { doVersionsMatch, isNotNullOrUndefined, isNullOrUndefined } from '../utils/utils';
import { useCLMComponentsConfig } from './useCLMComponentsConfig';
import { useCLMSubcomponentHealth } from './useCLMSubcomponentHealth';
import { useCLMSubcomponentInfo } from './useCLMSubcomponentInfo';

type Props = {
  components?: Record<string, CLMComponent>;
};

export const useValidation = ({ components }: Props) => {
  const { data } = useCLMComponentsConfig();
  const { data: subcomponentHealth } = useCLMSubcomponentHealth({ components: data?.components });
  const { data: subcomponentInfo } = useCLMSubcomponentInfo({ components: data?.components });

  const validation: Record<string, Record<string, CLMComponentValidation>> = {};

  if (isNotNullOrUndefined(components)) {
    const componentsAsArray = Object.values(components);

    componentsAsArray.forEach((component) => {
      validation[component.name] = {};

      Object.values(component.subComponents).forEach((subcomponent) => {
        const warnings: string[] = [];
        const errors: string[] = [];
        if (isNotNullOrUndefined(subcomponentInfo)) {
          const info = subcomponentInfo[component.name][subcomponent.name];

          if (isNullOrUndefined(info)) {
            warnings.push("Couldn't fetch subcomponent info");
          } else {
            if (info.build.version === '0.0.0') {
              warnings.push('Build version is configured wrong');
            }

            const semanticVersionRegex = /^\d+\.\d+\.\d+$/;
            if (!semanticVersionRegex.test(info.build.version)) {
              warnings.push('Feature branch instead of main branch provided');
            }

            const subcomponents = Object.values(component.subComponents).map((subcomponent) => subcomponent.name);
            const versions = subcomponents.map((sc) => {
              const scInfo = subcomponentInfo[component.name][sc];
              if (isNotNullOrUndefined(scInfo)) {
                return scInfo.build.version;
              } else {
                return '0.0.0';
              }
            });

            if (!doVersionsMatch(versions) && component.shouldSubcomponentsHaveMatchingVersion) {
              warnings.push('Subcomponents have mismatching versions');
            }

            if (new Date(info.build.time) < new Date(new Date().setMonth(new Date().getMonth() - 3))) {
              warnings.push('Build is older than 3 months');
            }
          }
        } else {
          warnings.push("Couldn't fetch subcomponent info");
        }

        if (isNotNullOrUndefined(subcomponentHealth)) {
          const health = subcomponentHealth[component.name][subcomponent.name];

          const healthComponents = health.components;

          if (isNotNullOrUndefined(healthComponents)) {
            const livenessState = healthComponents.livenessState;
            const readinessState = healthComponents.readinessState;
            if (
              health.status !== 'UP' &&
              isNotNullOrUndefined(livenessState) &&
              livenessState.status !== 'UP' &&
              isNotNullOrUndefined(readinessState) &&
              readinessState.status !== 'UP'
            ) {
              errors.push(`Subcomponent status ${health.status}`);
            } else if (
              health.status !== 'UP' &&
              ((isNotNullOrUndefined(livenessState) && livenessState.status === 'UP') ||
                (isNotNullOrUndefined(readinessState) && readinessState.status === 'UP'))
            ) {
              warnings.push('Subcomponent is down but liveness or readiness probe is active');
            } else if (health.status === 'UP' && isNotNullOrUndefined(livenessState) && livenessState.status !== 'UP') {
              warnings.push('Subcomponent liveness probe is down');
            } else if (
              health.status === 'UP' &&
              isNotNullOrUndefined(readinessState) &&
              readinessState.status !== 'UP'
            ) {
              warnings.push('Subcomponent readiness probe is down');
            }
          } else {
            if (health.status !== 'UP') {
              errors.push(`Subcomponent status down`);
            }
          }
        } else {
          errors.push("Couldn't fetch subcomponent health");
        }

        validation[component.name][subcomponent.name] = {
          warnings,
          errors,
          tooltip: () => {
            const tooltip: React.ReactNode | null = null;
            const tooltipWarnings: React.ReactNode[] = [];
            const tooltipErrors: React.ReactNode[] = [];

            if (warnings.length > 0) {
              warnings.forEach((warning) => {
                tooltipWarnings.push(<Box key={warning.toLowerCase().replaceAll(' ', '-')}>- {warning}</Box>);
              });
            }

            if (errors.length > 0) {
              errors.forEach((error) => {
                tooltipErrors.push(<Box key={error.toLowerCase().replaceAll(' ', '-')}>- {error}</Box>);
              });
            }

            if (tooltipWarnings.length > 0 || tooltipErrors.length > 0) {
              return (
                <Box>
                  {tooltipWarnings.length > 0 ? <Box sx={{ fontWeight: 'bold' }}>Warnings</Box> : null}
                  {tooltipWarnings}
                  {tooltipErrors.length > 0 ? <Box sx={{ fontWeight: 'bold' }}>Errors</Box> : null}
                  {tooltipErrors}
                </Box>
              );
            }

            return tooltip;
          },
          color: () => {
            if (errors.length === 0 && warnings.length === 0) {
              return JBColors.support.green['100'];
            } else if (warnings.length > 0 && errors.length === 0) {
              return JBColors.support.yellow['110'];
            } else if (errors.length > 0) {
              return JBColors.support.red['100'];
            } else {
              return JBColors.support.red['100'];
            }
          },
        };
      });
    });
  }

  return validation;
};
