import React from 'react';

import { QueryClient } from '@tanstack/react-query';
import { createRootRouteWithContext, createRoute, createRouter, redirect } from '@tanstack/react-router';

import App from '../App';
import { Loader } from '../components/Loader';
import { getRuntimeConfig } from '../hooks/useRuntimeConfig';
import { infoOverviewFilter } from '../schemas/infoOverviewFilter';
import { RuntimeConfig } from '../types';
import { isNotNullOrUndefined } from '../utils/utils';
import DrilldownPage from './DrilldownPage';
import ImpersonatePage from './ImpersonatePage';
import InfoOverviewPage from './InfoOverviewPage';

const rootRoute = createRootRouteWithContext<{
  queryClient: QueryClient;
}>()({
  component: App,
});

const indexRoute = createRoute({
  getParentRoute: () => rootRoute,
  path: '/',
  loader: async () => redirect({ to: '/impersonate' }),
  pendingComponent: () => <Loader />,
});

const impersonateRoute = createRoute({
  getParentRoute: () => rootRoute,
  path: '/impersonate',
  component: ImpersonatePage,
  pendingComponent: () => <Loader />,
  beforeLoad: async ({ context }) => {
    const queryClient = context.queryClient;

    const runtimeConfig = await queryClient.ensureQueryData<RuntimeConfig>(getRuntimeConfig());

    if (isNotNullOrUndefined(runtimeConfig.featureFlags) && !runtimeConfig.featureFlags.userImpersonation.enabled) {
      throw redirect({
        to: '/info',
      });
    }
  },
});

const infoOverviewRoute = createRoute({
  getParentRoute: () => rootRoute,
  path: '/info',
  component: InfoOverviewPage,
  validateSearch: (search) => infoOverviewFilter.parse(search),
});

const infoDrilldownRoute = createRoute({
  getParentRoute: () => rootRoute,
  path: '/info/component/$subcomponent',
  component: DrilldownPage,
});

const routeTree = rootRoute.addChildren([indexRoute, impersonateRoute, infoOverviewRoute, infoDrilldownRoute]);

export const router = createRouter({
  routeTree,
  context: {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    queryClient: undefined!,
  },
});

declare module '@tanstack/react-router' {
  interface Register {
    router: typeof router;
  }
}
