import React from 'react';

import { JBColors } from '@juliusbaer-gcrm/gcrm-frontend-components';
import { Box, Grid2, Typography } from '@mui/material';
import { useSearch } from '@tanstack/react-router';

import { useCLMComponentsConfig } from '../hooks/useCLMComponentsConfig';
import { useCLMSubcomponentHealth } from '../hooks/useCLMSubcomponentHealth';
import { useCLMSubcomponentInfo } from '../hooks/useCLMSubcomponentInfo';
import { CLMComponent } from '../types';
import { isNotNullOrUndefined } from '../utils/utils';
import { CLMSubcomponentListItem } from './CLMSubcomponentListItem';

type Props = {
  component: CLMComponent;
};

export const CLMComponentListItem: React.FC<Props> = ({ component }) => {
  const searchParams = useSearch({ from: '/info' });
  const { data } = useCLMComponentsConfig();
  const { data: subcomponentHealth } = useCLMSubcomponentHealth({ components: data?.components });
  const { data: subcomponentInfo } = useCLMSubcomponentInfo({ components: data?.components });

  const shouldDisplayComponent = () => {
    if (isNotNullOrUndefined(searchParams.status) && isNotNullOrUndefined(subcomponentHealth)) {
      const selectedStatuses = searchParams.status?.split(',');

      const statuses = Object.values(component.subComponents).map(
        (subcomponent) => subcomponentHealth[component.name][subcomponent.name].status,
      );

      return statuses.some((status) => selectedStatuses.includes(status));
    }

    return true;
  };

  if (!shouldDisplayComponent()) return null;

  return (
    <Grid2 size={1}>
      <Box
        sx={{
          backgroundColor: JBColors.core.stone['40'],
          padding: '1rem',
          margin: '10px',
          display: 'flex',
          flexDirection: 'column',
          gap: '10px',
        }}
        data-testid={`clm-component-list-item-${component.name}`}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              gap: '5px',
              flexDirection: 'column',
            }}
          >
            <Box
              sx={{
                fontWeight: 'bold',
                fontSize: '1.1rem',
              }}
            >
              {component.name}
            </Box>
            <Box
              sx={{
                fontSize: '0.8rem',
              }}
            >
              {component.team}
            </Box>
          </Box>
          <Box>
            <img
              src={`/teams/${component.team}.png`}
              alt={`${component.team} Image`}
              style={{
                width: '50px',
              }}
            />
          </Box>
        </Box>
        <Box>
          <Typography
            sx={{
              fontWeight: 'bold',
              fontSize: '0.8rem',
            }}
          >
            Subcomponents
          </Typography>
          <Box>
            {Object.values(component.subComponents).map((subcomponent, index) => (
              <CLMSubcomponentListItem
                key={subcomponent.name}
                index={index}
                component={component}
                subcomponent={subcomponent}
                subcomponentHealth={subcomponentHealth}
                subcomponentInfo={subcomponentInfo}
              />
            ))}
          </Box>
        </Box>
      </Box>
    </Grid2>
  );
};
