import React from 'react';
import 'react-json-view-lite/dist/index.css';
import { allExpanded, defaultStyles, JsonView } from 'react-json-view-lite';

import { Box, Button, Modal } from '@mui/material';

type Props = {
  data: any;
  open: boolean;
  onClose: () => void;
};

export const JsonViewModal = ({ data, open, onClose }: Props) => {
  return (
    <Modal
      open={open}
      data-testid="jsonview-modal"
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Box
        sx={{
          backgroundColor: '#EEE',
          width: '75%',
          height: '75%',
          padding: '2rem',
          overflowY: 'scroll',
          position: 'relative',
        }}
      >
        <Box
          sx={{
            position: 'absolute',
            top: '1rem',
            right: '1rem',
          }}
        >
          <Button onClick={() => onClose()}>close</Button>
        </Box>
        <JsonView data={data} shouldExpandNode={() => allExpanded()} style={defaultStyles} clickToExpandNode={true} />
      </Box>
    </Modal>
  );
};
