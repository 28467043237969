import React, { useEffect, useState } from 'react';

import { Box, Button, Container, Divider, Grid, Stack } from '@mui/material';
import { Formik } from 'formik';
import { JBCopyButton } from '@juliusbaer-gcrm/gcrm-frontend-components';

import { LinkError } from '../../errorHandling/LinkError';
import { useUserData } from '../../hooks/useUsers';
import LinkService from '../../services/LinkService';
import { isNotNullOrUndefined, isNullOrUndefined } from '../../utils/utils';
import { AvailableData } from '../AvailableData/AvailableData';
import { convertUserToAvailableFilters, filterUsers } from '../AvailableData/AvailableDataFilter';
import { ConfigurationDownloadButton } from '../ConfigurationDownloadButton';
import { initialValues, LoginTypeDto } from '../types/LoginType';
import AutocompleteComponent from '../Autocomplete/EnhancedAutocomplete';
import { useRuntimeConfig } from '../../hooks/useRuntimeConfig';

const useLoginData = () => {
  const userData = useUserData();
  return convertUserToAvailableFilters(userData.users);
};

const Login: React.FC = () => {
  const allAvailableUsers = useLoginData();
  const autocompleteStyles = { minWidth: 200, maxWidth: 300, width: '100%' };
  const [availableUsers, setAvailableUsers] = useState<AvailableData>(allAvailableUsers);
  const [selectedValues, setSelectedValues] = useState(initialValues);
  const [currentSelectedUser, setCurrentSelectedUser] = useState<LoginTypeDto | null>(null);
  const { data: environment } = useRuntimeConfig();

  useEffect(() => {
    const filtered = filterUsers(selectedValues, allAvailableUsers);
    setAvailableUsers((prevAvailableUsers) => {
      if (isNullOrUndefined(prevAvailableUsers) || filtered.length !== prevAvailableUsers.users.length) {
        return convertUserToAvailableFilters(filtered);
      }
      return prevAvailableUsers;
    });

    if (filtered.length === 1 && selectedValues.userId === filtered[0].userId) {
      setCurrentSelectedUser(filtered[0]);
    } else {
      setCurrentSelectedUser(null);
    }
  }, [selectedValues, allAvailableUsers]);

  const handleInputChange = (field: keyof typeof selectedValues, value: string) => {
    setSelectedValues((prevValues) => ({
      ...prevValues,
      [field]: value,
    }));
  };

  const onSubmit = async () => {
    try {
      const selectedUser = allAvailableUsers.users.find((user) => user.userId === selectedValues.userId);
      if (isNotNullOrUndefined(selectedUser)) {
        const links = await LinkService.renderLinks(selectedValues.userId, selectedUser.password);
        for (const link of links) {
          window.open(link, '_blank');
        }
      }
    } catch (error) {
      throw new LinkError(`Links not found - ${error}`);
    }
  };

  return (
    <Container maxWidth={'xl'}>
      <Formik initialValues={initialValues} onSubmit={onSubmit} validationSchema={null}>
        {() => (
          <Grid container direction="column" alignItems="center" justifyContent="center">
            <Box
              component={'form'}
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'flex-end',
                gap: '1rem',
                p: 1,
                m: 1,
                bgcolor: 'background.paper',
                borderRadius: 1,
              }}
            >
              <Grid item>
                <Stack direction="row" spacing={2}>
                  <AutocompleteComponent
                    label="Roles"
                    options={availableUsers.roles}
                    inputValue={selectedValues.role}
                    onInputChange={(event, newInputValue) => handleInputChange('role', newInputValue)}
                    id="roles"
                    sx={autocompleteStyles}
                    dataTestId="role-dropdown"
                  />
                  <AutocompleteComponent
                    label="Data Scope Types"
                    options={availableUsers.dataScopeTypes}
                    inputValue={selectedValues.dataScopeType}
                    onInputChange={(event, newInputValue) => handleInputChange('dataScopeType', newInputValue)}
                    id="dataScopes"
                    sx={autocompleteStyles}
                    dataTestId="data-scope-dropdown"
                  />
                  <AutocompleteComponent
                    label="Ou Data Scopes"
                    options={availableUsers.ouDataScopes}
                    inputValue={selectedValues.ouDataScope}
                    onInputChange={(event, newInputValue) => handleInputChange('ouDataScope', newInputValue)}
                    id="ouDataScope"
                    sx={autocompleteStyles}
                    dataTestId="ou-data-scope-dropdown"
                  />
                  <AutocompleteComponent
                    label="Users"
                    options={availableUsers.users}
                    inputValue={selectedValues.userId}
                    onInputChange={(event, newInputValue) => handleInputChange('userId', newInputValue)}
                    id="userId"
                    getOptionLabel={(user) => user.userId}
                    sx={autocompleteStyles}
                    dataTestId="user-id-dropdown"
                  />
                  <Button variant="text" onClick={() => onSubmit()} data-testid="login-button">
                    Login
                  </Button>
                </Stack>
              </Grid>
              <Box
                sx={{
                  display: 'flex',
                  width: '100%',
                  justifyContent: 'center',
                }}
              >
                <Box>
                  <ConfigurationDownloadButton user={currentSelectedUser} />
                </Box>
                <Box>
                  <Divider
                    orientation="vertical"
                    sx={{
                      borderColor: 'black',
                      mx: 2,
                    }}
                  />
                </Box>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '0.5rem',
                  }}
                >
                  <Box
                    sx={{
                      display: 'flex',
                      justifyItems: 'center',
                      alignItems: 'center',
                      gap: '1rem',
                      justifyContent: 'space-between',
                    }}
                  >
                    <JBCopyButton
                      data-testid="copy-button-username"
                      onFailedCopy={() => ({})}
                      variant="outlined"
                      size="small"
                      textToCopy={
                        isNotNullOrUndefined(environment)
                          ? environment.env === 'local'
                            ? selectedValues.userId
                            : selectedValues.userId + '@juliusbaer.com'
                          : selectedValues.userId
                      }
                    >
                      Username
                    </JBCopyButton>
                    <Box>
                      {isNotNullOrUndefined(environment)
                        ? environment.env === 'local'
                          ? selectedValues.userId
                          : selectedValues.userId + '@juliusbaer.com'
                        : null}
                    </Box>
                  </Box>
                  <Box
                    sx={{
                      display: 'flex',
                      justifyItems: 'center',
                      alignItems: 'center',
                      gap: '1rem',
                      justifyContent: 'space-between',
                    }}
                  >
                    <JBCopyButton
                      data-testid="copy-button-password"
                      onFailedCopy={() => ({})}
                      variant="outlined"
                      size="small"
                      textToCopy={currentSelectedUser?.password}
                      onSuccessfulCopy={() => ({})}
                    >
                      Password
                    </JBCopyButton>
                    <Box>{currentSelectedUser?.password}</Box>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Grid>
        )}
      </Formik>
    </Container>
  );
};

export default Login;
