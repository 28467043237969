import React from 'react';

import { Grid2 } from '@mui/material';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

import { CLMSubcomponent } from '../types';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

export function SwaggerURLTable({ subcomponent }: { subcomponent: CLMSubcomponent }) {
  const urls = subcomponent?.swaggerUrls;

  if (urls === undefined || urls === null) {
    throw new Error('No SwaggerURLS');
  }

  return (
    <>
      <Grid2
        sx={{
          display: 'flex',
          height: '100%',
        }}
      >
        <TableContainer component={Paper} data-testid="swagger-table">
          <Table aria-label="customized table">
            <TableHead>
              <TableRow>
                <StyledTableCell>Environment</StyledTableCell>
                <StyledTableCell align="left">URLS</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {Object.keys(urls).map((key) => (
                <StyledTableRow key={key}>
                  <StyledTableCell component="th" scope="row">
                    {key}
                  </StyledTableCell>
                  <StyledTableCell align="left">
                    <a href={urls[key]}>{urls[key]}</a>
                  </StyledTableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid2>
    </>
  );
}
